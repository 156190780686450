<template>
  <div style="margin:8%;">
    <!-- <b-row>
      <b-col>
        <b-input-group-append class="searchQabul">
          <b-form-input
            style="background-color:#F5F7FA"
            size="lg"
            class="searchinput"
            @keyup="search"
          >
          </b-form-input>
          <img
            @keyup="search"
            class="imagesQabul"
            width="25px"
            src="@/assets/pictures/Сгруппировать 6960.png"
          />
          <div
            class="dropdown-select-input"
            v-if="searchshow"
            v-click-outside="closeschoolinfo"
          >
            <ul>
              <li v-for="(item, index) in items" :key="index">
                <h6 class="mb-0">
                  {{ index + 1 }}.
                  <a
                    @click="$router.push({ name: item.routername })"
                    class="my-text-success"
                  >
                    {{ item.fullname }}
                  </a>
                </h6>
               
              </li>
              <li v-if="items.length == 0">
                <p class="text-center mb-0">
                  {{ $t("nothinghere") }}
                </p>
              </li>
            </ul>
          </div>
        </b-input-group-append>
      </b-col>
      <b-col></b-col>
      <b-col></b-col>
    </b-row> -->
    <b-row class="mt-4">
      <b-col>
        <p class="TextBlack32">{{ $t("Qa'bul") }}</p>
        <!-- <p class="TextBlack17">
          Вся актуальная информация об образовании в Узбекистане
        </p> -->
        <!-- <hr style="width : 50px;" class="bg-dark" /> -->
      </b-col>
    </b-row>
    <hr class="mb-0 pb-0" />

    <b-row>
      <b-col>
        <p
          @click="TabIndex = 1"
          style="margin-top:5%;text-decoration:underline;cursor: pointer "
          :class="{ TextActive: TabIndex == 1 }"
        >
          {{ $t("qabul_1") }}
        </p>
        <p
          @click="TabIndex = 2"
          style="margin-top:5%;text-decoration:underline;cursor: pointer"
          :class="{ TextActive: TabIndex == 2 }"
        >
          {{ $t("qabul_2") }}
        </p>
        <p
          @click="TabIndex = 3"
          style="margin-top:5%;text-decoration:underline;cursor: pointer"
          :class="{ TextActive: TabIndex == 3 }"
        >
          {{ $t("qabul_3") }}
        </p>
        <p
          @click="TabIndex = 4"
          style="margin-top:5%;text-decoration:underline;cursor: pointer"
          :class="{ TextActive: TabIndex == 4 }"
        >
          {{ $t("qabul_4") }}
        </p>
        <p
          @click="TabIndex = 5"
          style="margin-top:5%;text-decoration:underline;cursor: pointer"
          :class="{ TextActive: TabIndex == 5 }"
        >
          {{ $t("qabul_5") }}
        </p>
      </b-col>
      <svg
        style="margin-top:3%"
        xmlns="http://www.w3.org/2000/svg"
        width="66"
        height="2"
        viewBox="0 0 66 2"
      >
        <line
          id="Линия_46"
          data-name="Линия 46"
          x2="64"
          transform="translate(1 1)"
          fill="none"
          stroke="#52a0ff"
          stroke-linecap="round"
          stroke-width="2"
        />
      </svg>
      <svg
        style="margin-top:3%"
        xmlns="http://www.w3.org/2000/svg"
        width="66"
        height="2"
        viewBox="0 0 66 2"
      >
        <line
          id="Линия_46"
          data-name="Линия 46"
          x2="64"
          transform="translate(1 1)"
          fill="none"
          stroke="#52a0ff"
          stroke-linecap="round"
          stroke-width="2"
        />
      </svg>
      <b-col>
        <transition name="fade">
          <div v-if="TabIndex == 1">
            <p style=" color: #52a0ff;" class="Text17 mt-4 pt-4 mb-0 pb-0">
              {{ $t("Murojaat qilish") }}
            </p>
            <p class="school15 mt-0 pt-0">
              {{ $t("Davlat_xizmatlar_markazi") }} <br />
              {{
                $t(
                  "Elektron_tarzda_davlat_xizmatlaridan_foydalanish_uchun_yagona_interaktiv_xizmatlari_portali"
                )
              }}
            </p>

            <p style=" color: #52a0ff;" class="Text17 mt-2  mb-0 pb-0">
              {{ $t("Mas'ul tashkilot") }}
            </p>
            <p class="school15 mt-0 pt-0">{{ $t("OzResXTV") }}</p>

            <p style=" color: #52a0ff;" class="Text17 mt-2 mb-0 pb-0">
              {{ $t("Foydalanuvchilar") }}
            </p>
            <p class="school15 mt-0 pt-0">{{ $t("Jismoniy_shaxs") }}</p>

            <p style=" color: #52a0ff;" class="Text17 mt-2  mb-0 pb-0">
              {{ $t("Xizmatni ko'rsatish uchun kerak bo'ladigan hujjatlar") }}
            </p>
            <p class="school15 mt-0 pt-0">
              {{
                $t(
                  "Ixtisoslashtirilgan_umum_talim_muassasasi_ixtisoslashtirilgan_sinfi_mavjud_umum_talim_maktabi_direktori_nomiga_ariza"
                )
              }}
              <br />{{
                $t(
                  "Tugilganlik_togrisidagi_guvohnomaning_nusxasi_3_x_4_hajmdagi_2_ta_fotosurat"
                )
              }}
            </p>

            <p style=" color: #52a0ff;" class="Text17 mt-2  mb-0 pb-0">
              {{ $t("Ko'rib chiqish muddati") }}
            </p>
            <p class="school15 mt-0 pt-0">--------</p>

            <p style=" color: #52a0ff;" class="Text17 mt-2 mb-0 pb-0">
              {{ $t("Huquqiy asosi") }}
            </p>
            <p class="school15 mt-0 pt-0">
              <a
                target="_blank"
                style="display:block; margin-top:auto; margin-bottom:auto;"
                :href="
                  $t('lang') == 'Русский язык'
                    ? 'https://lex.uz/ru/docs/5172617'
                    : $t('lang') == 'Ўзбек тили'
                    ? 'https://lex.uz/ru/docs/5165290'
                    : 'https://lex.uz/ru/docs/-5165290'
                "
                >{{
                  $t(
                    "OzResAdilya_vazirligi_OzResPrezident_huzuridagi_loyiha_boshqaruvi"
                  )
                }}</a
              >
            </p>

            <p style=" color: #52a0ff;" class="Text17 mt-2  mb-0 pb-0">
              {{ $t("Xizmat narxi") }}
            </p>
            <p class="school15 mt-0 pt-0">{{ $t("Bepul") }}</p>

            <p style=" color: #52a0ff;" class="Text17 mt-2 mb-0 pb-0">
              {{ $t("Davlat xizmat ko'rsatish shakli") }}
            </p>
            <p class="school15 mt-0 pt-0">
              {{ $t("ananaviy_va_elektron_shaklda") }}
            </p>
          </div>
        </transition>
        <!-- **************************************************** -->
        <transition name="fade">
          <div v-if="TabIndex == 2">
            <p style=" color: #52a0ff;" class="Text17 mt-4 pt-4 mb-0 pb-0">
              {{ $t("Murojaat qilish") }}
            </p>
            <p class="school15 mt-0 pt-0">
              {{ $t("Davlat_xizmatlar_markazi") }} <br />{{
                $t(
                  "Elektron_tarzda_davlat_xizmatlaridan_foydalanish_uchun_yagona_interaktiv_xizmatlari_portali"
                )
              }}
            </p>

            <p style=" color: #52a0ff;" class="Text17 mt-2  mb-0 pb-0">
              {{ $t("Mas'ul tashkilot") }}
            </p>
            <p class="school15 mt-0 pt-0">{{ $t("OzResXTV") }}</p>

            <p style=" color: #52a0ff;" class="Text17 mt-2 mb-0 pb-0">
              {{ $t("Foydalanuvchilar") }}
            </p>
            <p class="school15 mt-0 pt-0">{{ $t("Jismoniy_shaxs") }}</p>

            <p style=" color: #52a0ff;" class="Text17 mt-2  mb-0 pb-0">
              {{ $t("Xizmatni ko'rsatish uchun kerak bo'ladigan hujjatlar") }}
            </p>
            <p class="school15 mt-0 pt-0">
              {{
                $t(
                  "Ixtisoslashtirilgan_umum_talim_muassasasi_ixtisoslashtirilgan_sinfi_mavjud_umum_talim_maktabi_direktori_nomiga_ariza"
                )
              }}
              <br />
              {{
                $t(
                  "Tugilganlik_togrisidagi_guvohnomaning_nusxasi_3_x_4_hajmdagi_2_ta_fotosurat"
                )
              }}
            </p>

            <p style=" color: #52a0ff;" class="Text17 mt-2  mb-0 pb-0">
              {{ $t("Ko'rib chiqish muddati") }}
            </p>
            <p class="school15 mt-0 pt-0">--------</p>

            <p style=" color: #52a0ff;" class="Text17 mt-2 mb-0 pb-0">
              {{ $t("Huquqiy asosi") }}
            </p>
            <p class="school15 mt-0 pt-0">
              <a
                target="_blank"
                style="display:block; margin-top:auto; margin-bottom:auto;"
                :href="
                  $t('lang') == 'Русский язык'
                    ? 'https://lex.uz/ru/docs/5172617'
                    : $t('lang') == 'Ўзбек тили'
                    ? 'https://lex.uz/ru/docs/5165290'
                    : 'https://lex.uz/ru/docs/-5165290'
                "
                >{{
                  $t(
                    "OzResAdilya_vazirligi_OzResPrezident_huzuridagi_loyiha_boshqaruvi"
                  )
                }}</a
              >
            </p>

            <p style=" color: #52a0ff;" class="Text17 mt-2  mb-0 pb-0">
              {{ $t("Xizmat narxi") }}
            </p>
            <p class="school15 mt-0 pt-0">{{ $t("Bepul") }}</p>

            <p style=" color: #52a0ff;" class="Text17 mt-2 mb-0 pb-0">
              {{ $t("Davlat xizmat ko'rsatish shakli") }}
            </p>
            <p class="school15 mt-0 pt-0">
              {{ $t("ananaviy_va_elektron_shaklda") }}
            </p>
          </div>
        </transition>
        <!-- ******************************************** -->
        <transition name="fade">
          <div v-if="TabIndex == 3">
            <p style=" color: #52a0ff;" class="Text17 mt-4 pt-4 mb-0 pb-0">
              {{ $t("Murojaat qilish") }}
            </p>
            <p class="school15 mt-0 pt-0">{{ $t("OzResXTV") }}</p>

            <p style=" color: #52a0ff;" class="Text17 mt-2  mb-0 pb-0">
              {{ $t("Mas'ul tashkilot") }}
            </p>
            <p class="school15 mt-0 pt-0">{{ $t("OzResXTV") }}</p>

            <p style=" color: #52a0ff;" class="Text17 mt-2 mb-0 pb-0">
              {{ $t("Foydalanuvchilar") }}
            </p>
            <p class="school15 mt-0 pt-0">{{ $t("Jismoniy_shaxs") }}</p>

            <p style=" color: #52a0ff;" class="Text17 mt-2  mb-0 pb-0">
              {{ $t("Xizmatni ko'rsatish uchun kerak bo'ladigan hujjatlar") }}
            </p>
            <p class="school15 mt-0 pt-0">
              {{
                $t(
                  "Ixtisoslashtirilgan_umum_talim_muassasasi_ixtisoslashtirilgan_sinfi_mavjud_umum_talim_maktabi_direktori_nomiga_ariza"
                )
              }}
              <br />
              {{
                $t(
                  "Tugilganlik_togrisidagi_guvohnomaning_nusxasi_3_x_4_hajmdagi_2_ta_fotosurat"
                )
              }}
            </p>

            <p style=" color: #52a0ff;" class="Text17 mt-2  mb-0 pb-0">
              {{ $t("Ko'rib chiqish muddati") }}
            </p>
            <p class="school15 mt-0 pt-0">-------</p>

            <p style=" color: #52a0ff;" class="Text17 mt-2 mb-0 pb-0">
              {{ $t("Huquqiy asosi") }}
            </p>
            <p class="school15 mt-0 pt-0">
              <a
                target="_blank"
                style="display:block; margin-top:auto; margin-bottom:auto;"
                href="https://lex.uz/ru/docs/-4511709"
                >{{
                  $t(
                    "OzResAdilya_vazirligi_OzResPrezident_huzuridagi_loyiha_boshqaruvi"
                  )
                }}</a
              >
            </p>

            <p style=" color: #52a0ff;" class="Text17 mt-2  mb-0 pb-0">
              {{ $t("Xizmat narxi") }}
            </p>
            <p class="school15 mt-0 pt-0">{{ $t("Bepul") }}</p>

            <p style=" color: #52a0ff;" class="Text17 mt-2 mb-0 pb-0">
              {{ $t("Davlat xizmat ko'rsatish shakli") }}
            </p>
            <p class="school15 mt-0 pt-0">
              {{ $t("ananaviy_va_elektron_shaklda") }}
            </p>
          </div>
        </transition>
        <!-- ********************************************** -->
        <transition name="fade">
          <div v-if="TabIndex == 4">
            <p style=" color: #52a0ff;" class="Text17 mt-4 pt-4 mb-0 pb-0">
              {{ $t("Murojaat qilish") }}
            </p>
            <p class="school15 mt-0 pt-0">{{ $t("OzResXTV") }}</p>

            <p style=" color: #52a0ff;" class="Text17 mt-2  mb-0 pb-0">
              {{ $t("Mas'ul tashkilot") }}
            </p>
            <p class="school15 mt-0 pt-0">{{ $t("OzResXTV") }}</p>

            <p style=" color: #52a0ff;" class="Text17 mt-2 mb-0 pb-0">
              {{ $t("Foydalanuvchilar") }}
            </p>
            <p class="school15 mt-0 pt-0">{{ $t("Jismoniy_shaxs") }}</p>

            <p style=" color: #52a0ff;" class="Text17 mt-2  mb-0 pb-0">
              {{ $t("Xizmatni ko'rsatish uchun kerak bo'ladigan hujjatlar") }}
            </p>
            <p class="school15 mt-0 pt-0">
              {{
                $t(
                  "Umumtalim_muassasasiga_yollanma_bilan_birga_bolaning_sogligi_togrisidagi_tibbiy_malumotnoma_bolaning_35x4_sm"
                )
              }}
            </p>

            <p style=" color: #52a0ff;" class="Text17 mt-2  mb-0 pb-0">
              {{ $t("Ko'rib chiqish muddati") }}
            </p>
            <p class="school15 mt-0 pt-0">
              {{ $t("yollanma_olingan_kundan_boshlab_uch_ish_kuni_mobaynida") }}
            </p>

            <p style=" color: #52a0ff;" class="Text17 mt-2 mb-0 pb-0">
              {{ $t("Huquqiy asosi") }}
            </p>
            <p class="school15 mt-0 pt-0">
              <a
                target="_blank"
                style="display:block; margin-top:auto; margin-bottom:auto;"
                href="https://lex.uz/ru/docs/4367850"
                >{{
                  $t(
                    "Davlat_xizmatlari_markazi_yoki_OzResYagona_interaktiv_davlat_xizmatlari_portali"
                  )
                }}
              </a>
            </p>

            <p style=" color: #52a0ff;" class="Text17 mt-2  mb-0 pb-0">
              {{ $t("Xizmat narxi") }}
            </p>
            <p class="school15 mt-0 pt-0">{{ $t("Bepul") }}</p>

            <p style=" color: #52a0ff;" class="Text17 mt-2 mb-0 pb-0">
              {{ $t("Davlat xizmat ko'rsatish shakli") }}
            </p>
            <p class="school15 mt-0 pt-0">
              {{ $t("ananaviy_va_elektron_shaklda") }}
            </p>
          </div>
        </transition>
        <!-- *************************************************** -->
        <transition name="fade">
          <div v-if="TabIndex == 5">
            <p style=" color: #52a0ff;" class="Text17 mt-4 pt-4 mb-0 pb-0">
              {{ $t("Murojaat qilish") }}
            </p>
            <p class="school15 mt-0 pt-0">{{ $t("OzResXTV") }}</p>

            <p style=" color: #52a0ff;" class="Text17 mt-2  mb-0 pb-0">
              {{ $t("Mas'ul tashkilot") }}
            </p>
            <p class="school15 mt-0 pt-0">
              {{
                $t(
                  "QorResXTV_toshkent_shahar_xtv_bosh_boshqarmasi_viloyatlar_xalq_talim_boshqarmasi"
                )
              }}
            </p>

            <p style=" color: #52a0ff;" class="Text17 mt-2 mb-0 pb-0">
              {{ $t("Foydalanuvchilar") }}
            </p>
            <p class="school15 mt-0 pt-0">{{ $t("Jismoniy_shaxs") }}</p>

            <p style=" color: #52a0ff;" class="Text17 mt-2  mb-0 pb-0">
              {{ $t("Xizmatni ko'rsatish uchun kerak bo'ladigan hujjatlar") }}
            </p>
            <p class="school15 mt-0 pt-0">
              {{
                $t(
                  "Bola_ota_ona_yoki_ularning_ornini_bosuvchi_shaxslar_yoxud_notarial_ishonchnoma_orqali_belgilangan_vakilliklarning_bolani_"
                )
              }}
              <br />{{
                $t(
                  "Organilgan_fanlar_va_ular_boyicha_imtihonlarda_olingan_baholar_korsatilgan_malumoti_haqida_hujjatlarning_asl_nusxalari"
                )
              }}
              <br />
              {{ $t("Sogliq_togrisidagi_tibbiy_xulosa_milliy_pasport") }}
            </p>

            <p style=" color: #52a0ff;" class="Text17 mt-2  mb-0 pb-0">
              {{ $t("Ko'rib chiqish muddati") }}
            </p>
            <p class="school15 mt-0 pt-0">10 {{ $t("ish_kunida") }}</p>

            <p style=" color: #52a0ff;" class="Text17 mt-2 mb-0 pb-0">
              {{ $t("Huquqiy asosi") }}
            </p>
            <p class="school15 mt-0 pt-0">
              <a
                target="_blank"
                style="display:block; margin-top:auto; margin-bottom:auto;"
                href="https://lex.uz/ru/docs/1505055"
                >{{
                  $t(
                    "OzResDavlat_umumiy_orta_talim_muassasalariga_horijiy_fuqarolar_tomonida_oqishga_kirish"
                  )
                }}
              </a>
            </p>

            <p style=" color: #52a0ff;" class="Text17 mt-2  mb-0 pb-0">
              {{ $t("Xizmat narxi") }}
            </p>
            <p class="school15 mt-0 pt-0">{{ $t("Bepul") }}</p>

            <p style=" color: #52a0ff;" class="Text17 mt-2 mb-0 pb-0">
              {{ $t("Davlat xizmat ko'rsatish shakli") }}
            </p>
            <p class="school15 mt-0 pt-0">
              {{ $t("ananaviy_va_elektron_shaklda") }}
            </p>
          </div>
        </transition>
      </b-col>
      <div class="linevarticalQabul"></div>
    </b-row>
    <hr />
 <b-row>
            <!-- <b-col class="mt-4" sm="12" md="6">
              <b-card
                style="min-height: 120px"
                class="menuCard p-2 hover1 aboutButtons"
                @click="$router.push({ name: 'Qabul' })"
              >
                <div>
                  <div class="redCard" style="background-color:#082852">
                    <svg
                      class="svgCard"
                      xmlns="http://www.w3.org/2000/svg"
                      width="60"
                      height="55"
                      viewBox="0 0 60 55.714"
                    >
                      <path
                        id="reception_icon"
                        data-name="reception icon"
                        d="M2.143,55.714a2.143,2.143,0,1,1,0-4.286H27.857V47.143H4.286A4.291,4.291,0,0,1,0,42.857V38.571A29.858,29.858,0,0,1,5.124,21.8a30.075,30.075,0,0,1,13.2-10.869,29.717,29.717,0,0,1,9.534-2.283V4.286H23.571a2.143,2.143,0,0,1,0-4.286H36.429a2.143,2.143,0,0,1,0,4.286H32.143V8.646a29.852,29.852,0,0,1,14.63,5.048,30.082,30.082,0,0,1,10.869,13.2A29.809,29.809,0,0,1,60,38.571v4.286a4.29,4.29,0,0,1-4.285,4.286H32.143v4.286H57.857a2.143,2.143,0,1,1,0,4.286ZM19.991,14.878a25.692,25.692,0,0,0-15.7,23.693v3.3a.99.99,0,0,0,.989.989h49.45a.99.99,0,0,0,.989-.989v-3.3A25.729,25.729,0,0,0,19.991,14.878Z"
                      />
                    </svg>
                  </div>
                  <div>
                    <p class="menuCardText17 mb-0 ml-3 mt-1 cardTopMargin">
                      {{ $t("Qa'bul") }}
                    </p>
                    <p
                      style="maxLength: 15"
                      class="menuCardText14 mb-0 colorClass mt-1 ml-3 cardTopMargin"
                    >
                      {{ $t("Qabul_info") }}
                    </p>
                  </div>
                </div>
              </b-card>
            </b-col> -->
            <b-col class="mt-4" sm="12" md="6">
              <b-card
                style="min-height: 120px"
                class="menuCard p-2 hover1 aboutButtons"
                @click="$router.push({ name: 'ota_onalar_tulovi' })"
              >
                <div>
                  <div class="redCard" style="background-color:#082852">
                    <svg
                      class="svgCard"
                      xmlns="http://www.w3.org/2000/svg"
                      width="60"
                      height="55"
                      viewBox="0 0 64 44"
                    >
                      <path
                        id="card_icon"
                        data-name="card icon"
                        d="M8,44a8,8,0,0,1-8-8V8A8,8,0,0,1,8,0H56a8,8,0,0,1,8,8V36a8,8,0,0,1-8,8ZM4,8V36a4,4,0,0,0,4,4H56a4,4,0,0,0,4-4V8a4,4,0,0,0-4-4H8A4,4,0,0,0,4,8ZM44,28a4,4,0,0,1-4-4V20a4,4,0,0,1,4-4h8a4,4,0,0,1,4,4v4a4,4,0,0,1-4,4Zm0-7.5v3a.5.5,0,0,0,.5.5h7a.5.5,0,0,0,.5-.5v-3a.5.5,0,0,0-.5-.5h-7A.5.5,0,0,0,44,20.5Z"
                      />
                    </svg>
                  </div>
                  <div>
                    <p class="menuCardText17 mb-0 ml-3  cardTopMargin" style="font-weight:bold">
                      {{ $t("Ота_оналар_тўлови") }}
                    </p>
                    <p
                      style="maxLength: 15"
                      class="menuCardText14 mb-0 colorClass mt-1 ml-3 cardTopMargin"
                    >
                      {{ $t("ota_ona_tolov_info") }}
                    </p>
                  </div>
                </div>
              </b-card>
            </b-col>
            <b-col class="mt-4" sm="12" md="6">
              <b-card
                style="min-height: 120px"
                class="menuCard p-2 hover1 aboutButtons"
                @click="$router.push({ name: 'Oqishni-kochirish-va-tiklash' })"
              >
                <div>
                  <div class="redCard" style="background-color:#082852">
                    <svg
                      class="svgCard "
                      xmlns="http://www.w3.org/2000/svg"
                      width="60"
                      height="55"
                      viewBox="0 0 64 47.723"
                    >
                      <path
                        id="exchange_icon"
                        data-name="exchange icon"
                        d="M20.034,47.723h0A11.92,11.92,0,0,1,8.1,35.793l2.033-2.1,1.979,2.1a7.894,7.894,0,0,0,7.919,7.953H37.966a1.988,1.988,0,1,1,0,3.977ZM52.5,46.317l-8.139-8.139a1.988,1.988,0,1,1,2.812-2.812l4.715,4.715V11.931A8.015,8.015,0,0,0,43.9,3.977V0A11.945,11.945,0,0,1,54.888,7.287a11.885,11.885,0,0,1,.974,4.644V40.14l4.745-4.745a1.988,1.988,0,1,1,2.812,2.812l-8.11,8.11a1.988,1.988,0,0,1-2.812,0ZM12.115,35.792H8.1l.036-28.21L3.394,12.328a1.988,1.988,0,0,1-2.812,0,1.988,1.988,0,0,1,0-2.812l8.11-8.11a1.988,1.988,0,0,1,2.812,0l8.139,8.139a1.988,1.988,0,1,1-2.812,2.812L12.116,7.642v28.15ZM26.035,3.977a1.988,1.988,0,1,1,0-3.977H43.9L46,1.93,43.9,3.977H26.035Z"
                      />
                    </svg>
                  </div>
                  <div>
                    <p class="menuCardText17 mb-0 ml-3  cardTopMargin" style="font-weight:bold">
                      {{ $t("O'qishni ko'chirish va tiklash") }}
                    </p>
                    <p
                      style="maxLength: 15"
                      class="menuCardText14 mb-0 colorClass mt-1 ml-3 cardTopMargin"
                    >
                      {{ $t("oqish_kochirish_va_tiklash_info") }}
                    </p>
                  </div>
                </div>
              </b-card>
            </b-col>
            <b-col class="mt-4" sm="12" md="6">
              <b-card
                style="min-height: 120px"
                class="menuCard p-2 hover1 aboutButtons"
                @click="$router.push({ name: 'Ijtimoiy-Soha' })"
              >
                <div>
                  <div class="redCard" style="background-color:#082852">
                    <svg
                      class="svgCard"
                      xmlns="http://www.w3.org/2000/svg"
                      width="60"
                      height="55"
                      viewBox="0 0 64 59.151"
                    >
                      <path
                        id="social_icon"
                        data-name="social icon"
                        d="M47.14,58.084a13.5,13.5,0,0,1-3.369-2.047q-.353.163-.712.315a23.345,23.345,0,0,1-18.117,0q-.36-.152-.712-.315A13.577,13.577,0,1,1,10.291,33.067q.261-.11.524-.209a23.194,23.194,0,0,1,6.728-14.4,23.4,23.4,0,0,1,3.072-2.588,13.674,13.674,0,0,1-.192-2.29,13.576,13.576,0,1,1,27.151,0,13.686,13.686,0,0,1-.191,2.289,23.417,23.417,0,0,1,8.06,9.985,23.049,23.049,0,0,1,1.74,7.008q.264.1.524.209A13.579,13.579,0,0,1,47.14,58.084Zm1.51-3.574a9.7,9.7,0,0,0,8.52-17.394,23.2,23.2,0,0,1-6.714,14.249,23.393,23.393,0,0,1-3,2.539A9.7,9.7,0,0,0,48.65,54.51ZM8.719,38.719A9.7,9.7,0,0,0,20.546,53.9a23.413,23.413,0,0,1-7.991-9.936,23.045,23.045,0,0,1-1.726-6.851A9.682,9.682,0,0,0,8.719,38.719ZM34,54.3a19.277,19.277,0,0,0,7.045-1.319A13.572,13.572,0,0,1,52.424,32q.38,0,.757.021A19.342,19.342,0,0,0,47.713,21.2,19.6,19.6,0,0,0,46.1,19.747a13.585,13.585,0,0,1-24.192,0A19.61,19.61,0,0,0,20.286,21.2,19.344,19.344,0,0,0,14.818,32.02Q15.2,32,15.575,32A13.574,13.574,0,0,1,26.955,52.984,19.277,19.277,0,0,0,34,54.3ZM48.65,36.641A9.7,9.7,0,0,0,44.525,51.2a19.506,19.506,0,0,0,7.344-8.745,19.223,19.223,0,0,0,1.5-6.534q-.468-.045-.944-.045A9.634,9.634,0,0,0,48.65,36.641ZM16.13,42.458A19.5,19.5,0,0,0,23.474,51.2a9.695,9.695,0,0,0-7.9-15.325q-.476,0-.944.045A19.223,19.223,0,0,0,16.13,42.458ZM27.143,20.432a9.7,9.7,0,0,0,15.669-2.8q-.619-.316-1.264-.588a19.462,19.462,0,0,0-15.1,0q-.644.272-1.263.588A9.656,9.656,0,0,0,27.143,20.432ZM30.225,4.641A9.69,9.69,0,0,0,24.3,13.576q0,.085,0,.17.316-.145.637-.281a23.35,23.35,0,0,1,18.117,0q.321.136.636.28,0-.085,0-.17A9.7,9.7,0,0,0,30.225,4.641Z"
                        transform="translate(-2)"
                      />
                    </svg>
                  </div>
                  <div>
                    <p class="menuCardText17 mb-0 ml-3 cardTopMargin" style="font-weight:bold">
                      {{ $t("Ijtimoiy_Soha") }}
                    </p>
                    <p
                      style="maxLength: 15"
                      class="menuCardText14 mb-0 colorClass mt-1 ml-3 cardTopMargin"
                    >
                      {{ $t("Ijtimoiy_soha_info") }}
                    </p>
                  </div>
                </div>
              </b-card>
            </b-col>
            <b-col class="mt-4" sm="12" md="6">
              <b-card
                style="min-height: 120px"
                class="menuCard p-2 hover1 aboutButtons"
                @click="$router.push({ name: 'Rasmiy-hujjatlar' })"
              >
                <div>
                  <div class="redCard" style="background-color:#082852">
                    <svg
                      class="svgCard"
                      xmlns="http://www.w3.org/2000/svg"
                      width="60"
                      height="55"
                      viewBox="0 0 48 64"
                    >
                      <path
                        id="document_icon"
                        data-name="document icon"
                        d="M8,64a8,8,0,0,1-8-8V8A8,8,0,0,1,8,0H40a8,8,0,0,1,8,8V56a8,8,0,0,1-8,8ZM4,8V56a4,4,0,0,0,4,4H40a4,4,0,0,0,4-4V8a4,4,0,0,0-4-4H8A4,4,0,0,0,4,8Zm6,38a2,2,0,0,1,0-4H22a2,2,0,0,1,0,4Zm0-10a2,2,0,0,1,0-4H38a2,2,0,1,1,0,4Zm0-10a2,2,0,0,1,0-4H38a2,2,0,1,1,0,4Zm0-10a2,2,0,0,1,0-4H38a2,2,0,1,1,0,4Z"
                      />
                    </svg>
                  </div>
                  <div>
                    <p class="menuCardText17 mb-0 ml-3 cardTopMargin" style="font-weight:bold">
                      {{ $t("Rasmiy_hujjatlar") }}
                    </p>
                    <p
                      style="maxLength: 15"
                      class="menuCardText14 mb-0 colorClass mt-1 ml-3 cardTopMargin"
                    >
                       {{ $t("Rasmiy_hujjat_info") }}
                    </p>
                  </div>
                </div>
              </b-card>
            </b-col>
            <b-col class="mt-4" sm="12" md="6">
              <a
                href="https://oylik.uzedu.uz/#/"
                target="_blank"
                style="text-decoration: none;"
              >
                <b-card
                  style="min-height: 120px"
                  class="menuCard p-2 hover1 aboutButtons"
                >
                  <div>
                    <div class="redCard" style="background-color:#082852">
                      <svg
                        class="svgCard "
                        xmlns="http://www.w3.org/2000/svg"
                        width="60"
                        height="55"
                        viewBox="0 0 64 60.485"
                      >
                        <path
                          id="calculator_icon"
                          data-name="calculator icon"
                          d="M21.071,59.9,14,52.829,6.929,59.9A2,2,0,1,1,4.1,57.071L11.172,50,4.1,42.929A2,2,0,0,1,6.929,40.1L14,47.172,21.071,40.1A2,2,0,0,1,23.9,42.929L16.829,50,23.9,57.071A2,2,0,1,1,21.071,59.9ZM38,58a2,2,0,0,1,0-4H62a2,2,0,0,1,0,4Zm0-12a2,2,0,0,1,0-4H62a2,2,0,0,1,0,4ZM12,26V16H2a2,2,0,0,1,0-4H12V2a2,2,0,0,1,4,0V12H26a2,2,0,0,1,0,4H16V26a2,2,0,0,1-4,0ZM38,16a2,2,0,0,1,0-4H62a2,2,0,0,1,0,4Z"
                        />
                      </svg>
                    </div>
                    <div>
                      <p class="menuCardText17 mb-0 ml-3 cardTopMargin" style="font-weight:bold">
                        {{ $t("Calculator") }}
                      </p>
                      <p
                        style="maxLength: 15"
                        class="menuCardText14 mb-0 colorClass mt-1 ml-3 cardTopMargin"
                      >
                        {{ $t("calculator_info") }}
                      </p>
                    </div>
                  </div>
                </b-card>
              </a>
            </b-col>
          </b-row>
    <!-- <div class="row mt-3 d-flex justify-content-around">
      <div class="col-sm-12 mt-3  col-md-6 col-lg-4 text-center">
        <div
          style="min-height:300px"
          class="menuCard p-2 hover1 aboutButtons"
          @click="$router.push({ name: 'ota_onalar_tulovi' })"
        >
          <div class="text-left ml-3 mt-3">
            <svg
              class="svg1"
              xmlns="http://www.w3.org/2000/svg"
              width="64"
              height="44"
              viewBox="0 0 64 44"
            >
              <path
                id="card_icon"
                data-name="card icon"
                d="M8,44a8,8,0,0,1-8-8V8A8,8,0,0,1,8,0H56a8,8,0,0,1,8,8V36a8,8,0,0,1-8,8ZM4,8V36a4,4,0,0,0,4,4H56a4,4,0,0,0,4-4V8a4,4,0,0,0-4-4H8A4,4,0,0,0,4,8ZM44,28a4,4,0,0,1-4-4V20a4,4,0,0,1,4-4h8a4,4,0,0,1,4,4v4a4,4,0,0,1-4,4Zm0-7.5v3a.5.5,0,0,0,.5.5h7a.5.5,0,0,0,.5-.5v-3a.5.5,0,0,0-.5-.5h-7A.5.5,0,0,0,44,20.5Z"
              />
            </svg>
          </div>
          <p class="menuCardText24 text-left ml-3 mt-4">
            {{ $t("Ота_оналар_тўлови") }}
          </p>
          <p class="menuCardText17 text-left ml-3 mt-4">
            {{ $t("ota_ona_tolov_info") }}
          </p>
        </div>
      </div>
      <div class="col-sm-12 mt-3  col-md-6 col-lg-4 text-center">
        <div
          style="min-height:300px"
          class="menuCard p-2 hover1 aboutButtons"
          @click="$router.push({ name: 'Oqishni-kochirish-va-tiklash' })"
        >
          <div class="text-left ml-3 mt-3">
            <svg
              class="svg1"
              xmlns="http://www.w3.org/2000/svg"
              width="64"
              height="47.723"
              viewBox="0 0 64 47.723"
            >
              <path
                id="exchange_icon"
                data-name="exchange icon"
                d="M20.034,47.723h0A11.92,11.92,0,0,1,8.1,35.793l2.033-2.1,1.979,2.1a7.894,7.894,0,0,0,7.919,7.953H37.966a1.988,1.988,0,1,1,0,3.977ZM52.5,46.317l-8.139-8.139a1.988,1.988,0,1,1,2.812-2.812l4.715,4.715V11.931A8.015,8.015,0,0,0,43.9,3.977V0A11.945,11.945,0,0,1,54.888,7.287a11.885,11.885,0,0,1,.974,4.644V40.14l4.745-4.745a1.988,1.988,0,1,1,2.812,2.812l-8.11,8.11a1.988,1.988,0,0,1-2.812,0ZM12.115,35.792H8.1l.036-28.21L3.394,12.328a1.988,1.988,0,0,1-2.812,0,1.988,1.988,0,0,1,0-2.812l8.11-8.11a1.988,1.988,0,0,1,2.812,0l8.139,8.139a1.988,1.988,0,1,1-2.812,2.812L12.116,7.642v28.15ZM26.035,3.977a1.988,1.988,0,1,1,0-3.977H43.9L46,1.93,43.9,3.977H26.035Z"
              />
            </svg>
          </div>
          <p class="menuCardText22 text-left ml-3 mt-3 mb-0 pb-0">
            {{ $t("O'qishni ko'chirish va tiklash") }}
          </p>
          <p class="menuCardText17 text-left ml-3 mt-1">
            {{ $t("oqish_kochirish_va_tiklash_info") }}
          </p>
        </div>
      </div>

      <div class="col-sm-12 mt-3 col-md-6 col-lg-4 text-center">
        <div
          style="min-height:300px"
          class="menuCard p-2 hover1 aboutButtons"
          @click="$router.push({ name: 'Ijtimoiy-Soha' })"
        >
          <div class="text-left ml-3 mt-3">
            <svg
              class="svg1"
              xmlns="http://www.w3.org/2000/svg"
              width="64"
              height="59.151"
              viewBox="0 0 64 59.151"
            >
              <path
                id="social_icon"
                data-name="social icon"
                d="M47.14,58.084a13.5,13.5,0,0,1-3.369-2.047q-.353.163-.712.315a23.345,23.345,0,0,1-18.117,0q-.36-.152-.712-.315A13.577,13.577,0,1,1,10.291,33.067q.261-.11.524-.209a23.194,23.194,0,0,1,6.728-14.4,23.4,23.4,0,0,1,3.072-2.588,13.674,13.674,0,0,1-.192-2.29,13.576,13.576,0,1,1,27.151,0,13.686,13.686,0,0,1-.191,2.289,23.417,23.417,0,0,1,8.06,9.985,23.049,23.049,0,0,1,1.74,7.008q.264.1.524.209A13.579,13.579,0,0,1,47.14,58.084Zm1.51-3.574a9.7,9.7,0,0,0,8.52-17.394,23.2,23.2,0,0,1-6.714,14.249,23.393,23.393,0,0,1-3,2.539A9.7,9.7,0,0,0,48.65,54.51ZM8.719,38.719A9.7,9.7,0,0,0,20.546,53.9a23.413,23.413,0,0,1-7.991-9.936,23.045,23.045,0,0,1-1.726-6.851A9.682,9.682,0,0,0,8.719,38.719ZM34,54.3a19.277,19.277,0,0,0,7.045-1.319A13.572,13.572,0,0,1,52.424,32q.38,0,.757.021A19.342,19.342,0,0,0,47.713,21.2,19.6,19.6,0,0,0,46.1,19.747a13.585,13.585,0,0,1-24.192,0A19.61,19.61,0,0,0,20.286,21.2,19.344,19.344,0,0,0,14.818,32.02Q15.2,32,15.575,32A13.574,13.574,0,0,1,26.955,52.984,19.277,19.277,0,0,0,34,54.3ZM48.65,36.641A9.7,9.7,0,0,0,44.525,51.2a19.506,19.506,0,0,0,7.344-8.745,19.223,19.223,0,0,0,1.5-6.534q-.468-.045-.944-.045A9.634,9.634,0,0,0,48.65,36.641ZM16.13,42.458A19.5,19.5,0,0,0,23.474,51.2a9.695,9.695,0,0,0-7.9-15.325q-.476,0-.944.045A19.223,19.223,0,0,0,16.13,42.458ZM27.143,20.432a9.7,9.7,0,0,0,15.669-2.8q-.619-.316-1.264-.588a19.462,19.462,0,0,0-15.1,0q-.644.272-1.263.588A9.656,9.656,0,0,0,27.143,20.432ZM30.225,4.641A9.69,9.69,0,0,0,24.3,13.576q0,.085,0,.17.316-.145.637-.281a23.35,23.35,0,0,1,18.117,0q.321.136.636.28,0-.085,0-.17A9.7,9.7,0,0,0,30.225,4.641Z"
                transform="translate(-2)"
              />
            </svg>
          </div>
          <p class="menuCardText24 text-left ml-3 mt-3">
            {{ $t("Ijtimoiy_Soha") }}
          </p>
          <p class="menuCardText17 text-left ml-3 mt-3">
            {{ $t("Ijtimoiy_soha_info") }}
          </p>
        </div>
      </div>
      <div class="col-sm-12 mt-3 col-md-6 col-lg-4 text-center">
        <div
          style="min-height:300px"
          class="menuCard p-2 hover1 aboutButtons"
          @click="$router.push({ name: 'Rasmiy-hujjatlar' })"
        >
          <div class="text-left ml-3 mt-3">
            <svg
              class="svg1"
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="64"
              viewBox="0 0 48 64"
            >
              <path
                id="document_icon"
                data-name="document icon"
                d="M8,64a8,8,0,0,1-8-8V8A8,8,0,0,1,8,0H40a8,8,0,0,1,8,8V56a8,8,0,0,1-8,8ZM4,8V56a4,4,0,0,0,4,4H40a4,4,0,0,0,4-4V8a4,4,0,0,0-4-4H8A4,4,0,0,0,4,8Zm6,38a2,2,0,0,1,0-4H22a2,2,0,0,1,0,4Zm0-10a2,2,0,0,1,0-4H38a2,2,0,1,1,0,4Zm0-10a2,2,0,0,1,0-4H38a2,2,0,1,1,0,4Zm0-10a2,2,0,0,1,0-4H38a2,2,0,1,1,0,4Z"
              />
            </svg>
          </div>
          <p class="menuCardText24 text-left ml-3 mt-3">
            {{ $t("Rasmiy_hujjatlar") }}
          </p>
          <p class="menuCardText17 text-left ml-3 mt-3">
            {{ $t("Rasmiy_hujjat_info") }}
          </p>
        </div>
      </div>
      <div class="col-sm-12 mt-3 col-md-6 col-lg-4 text-center">
        <a
          href="http://salarycalc.webase.uz/#/"
          target="_blank"
          style="text-decoration: none;"
        >
          <div
            style="min-height:300px"
            class="menuCard p-2 hover1 aboutButtons"
          >
            <div class="text-left ml-3 mt-3">
              <svg
                class="svg1"
                xmlns="http://www.w3.org/2000/svg"
                width="64"
                height="60.485"
                viewBox="0 0 64 60.485"
              >
                <path
                  id="calculator_icon"
                  data-name="calculator icon"
                  d="M21.071,59.9,14,52.829,6.929,59.9A2,2,0,1,1,4.1,57.071L11.172,50,4.1,42.929A2,2,0,0,1,6.929,40.1L14,47.172,21.071,40.1A2,2,0,0,1,23.9,42.929L16.829,50,23.9,57.071A2,2,0,1,1,21.071,59.9ZM38,58a2,2,0,0,1,0-4H62a2,2,0,0,1,0,4Zm0-12a2,2,0,0,1,0-4H62a2,2,0,0,1,0,4ZM12,26V16H2a2,2,0,0,1,0-4H12V2a2,2,0,0,1,4,0V12H26a2,2,0,0,1,0,4H16V26a2,2,0,0,1-4,0ZM38,16a2,2,0,0,1,0-4H62a2,2,0,0,1,0,4Z"
                />
              </svg>
            </div>
            <p class="menuCardText24 text-left ml-3 mt-3">
              {{ $t("Calculator") }}
            </p>
            <p class="menuCardText17 text-left ml-3 mt-3">
              {{ $t("calculator_info") }}
            </p>
          </div>
        </a>
      </div>
      <div class="col-sm-12 mt-3 col-md-6 col-lg-4 text-center"></div>
    </div> -->
  </div>
</template>

<script>
import ServiceService from "@/services/Service.service";
export default {
  data() {
    return {
      TabIndex: 0,
      items: [],
      isBusy: false,
      searchshow: false,
      filter: {
        Search: "",
        SortColumn: "",
        OrderType: "asc",
        PageNumber: 1,
        PageLimit: 20,
        pageOptions: [5, 10, 15, 20, 25, 50, 100],
        SortIcon: "cil-arrow-top",
      },
    };
  },
  methods: {
    closeschoolinfo() {
      this.searchshow = false;
    },
    search() {
      this.searchshow = true;
      this.isBusy = true;
      ServiceService.GetList(
        this.filter.Search,
        this.filter.SortColumn,
        this.filter.OrderType,
        this.filter.PageNumber,
        this.filter.PageLimit
      ).then((res) => {
        this.isBusy = false;
        this.items = res.data.rows;
        // this.totalRows = res.data.total;
      });
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.BackgroundColorTabIndex1 {
  background-color: #0094c2;
}
</style>
